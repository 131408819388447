<template>
  <div class="all">
    <Nav></Nav>
    <div class="post-detail">
      <div class="posttext">
        <div class="title">{{ videoInfo.title }}</div>
        <div class="other">
          <div>组图ID：{{ videoInfo.frontPicId }}</div>
          <div>上传时间：{{ videoInfo.upTime }}</div>
          <div>地点：{{ videoInfo.eventAddr }}</div>
          <div v-for="k in videoInfo.typeStrings" :key="k">类型：{{ k }}</div>
        </div>
        <div class="zongText">总说明：{{ videoInfo.mainText }}</div>
      </div>
      <div class="video-url">
        <div class="playtitle">视频播放</div>
        <div class="play">
          <video
            :src="videoURL"
            :poster="videoimgURL"
            controls
            loop
            width="520"
            height="240"
          ></video>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  components: {
    Nav,
    Bottom,
  },
  data: function () {
    return {
      videoInfo: {}, // 单个视频的信息
      videoimgURL: "", // 封面
      videoURL: "", // 播放路径
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      console.log("paramsid", this.$route.query);
      var thiz = this;
      RD.post(this.$route.query.id)
        .one()
        .then((data) => {
          console.log("detail", data);
          thiz.videoInfo = data;
          thiz.videoimgURL = RD.video(thiz.videoInfo.id).poster_URL();
          thiz.videoURL = RD.video(thiz.videoInfo.id).mvideo_URL();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 1200px;
  margin: 0 auto;
  .post-detail {
    margin-top: 20px;
    height: auto;
    width: 100%;
    padding: 20px 0px;
    .posttext {
      width: 100%;
      height: 100%;
      .title {
        text-align: left;
        font-size: 3rem;
      }
      .other {
        width: 100%;
        height: 20px;
        margin-top: 10px;
        background-color: #bfbfbf;
        display: flex;
        font-size: 1.2rem;
        div {
          width: 250px;
          text-align: left;
        }
      }
      .zongText {
        width: 100%;
        margin-top: 30px;
        text-align: left;
        text-indent: 30px;
        line-height: 25px;
        font-size: 1.2rem;
      }
      .tag {
        display: flex;
        font-size: 1.2rem;
        margin-left: 30px;
        margin-top: 20px;
        .sign {
          display: flex;
          div {
            background-color: #dcdcdc;
            width: 50px;
            height: 20px;
            line-height: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .video-url {
    text-align: left;
    margin-top: 20px;
    .playtitle {
      height: 60px;
      font-size: 1.2rem;
    }
    .play {
      height: 80%;
      text-align: center;
    }
  }
}
</style>
